import React, { useState, useEffect } from 'react';
import './SettingsMenu.css'; // Ensure to create this CSS file
import SkipMarkForm from './SkipMarkForm';
import ApiService from '../../services/ApiService';
import ContentService from '../../services/ContentService';
import AnimixPremiumPopup from '../premium/AnimixPremiumPopup';

const SettingsMenu = ({ visible, videoRef, series, debugInfo, onSpeedChanged, onSrcChanged, onPremium }) => {
    const [quality, setQuality] = useState({ videoQuality: "FULL_HD" });
    const [qualities, setQualities] = useState([]);
    const [speed, setSpeed] = useState(1);
    const [dubbing, setDubbing] = useState('Стандарт');
    const [state, setState] = useState("def");

    useEffect(() => {
        // Fetch the list of qualities for the series when the component loads
        const fetchQualities = async () => {
            try {
                const response = await ApiService.getQualities(series.animeId, series.seriesNum, series.season); // Make sure to pass correct params
                if (response.type === "success") {
                    setQualities(response.data); // Extract the quality from each video


                    const availableQualities = response.data;

                    const savedPrefQuality = localStorage.getItem("savedPrefQuality");  console.log('112')

                    // Find the preferred quality if it exists and is different from FULL_HD
                    if (savedPrefQuality && savedPrefQuality !== "FULL_HD") {
                        const matchingQuality = availableQualities.find(quality => quality.videoQuality === savedPrefQuality);
                        if (matchingQuality && matchingQuality.privateId != null) {
                            setTimeout( async () => {
                                if (matchingQuality.privateId != null) {
                                    setQuality(matchingQuality);
                                    const src = await ContentService.fetchVideo(matchingQuality);
                                    
                                    onSrcChanged(src);
                                }
                               
                            }, 200);
                         
                            return; // Exit early if the preferred quality is found and set
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching qualities:", error);
            }
        };

        setQuality({ videoQuality: "FULL_HD" });
        fetchQualities();
    }, [series]);

    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        onSpeedChanged(newSpeed);
        setState('def');
    };

    const handleQualityChange = async (selectedQuality) => {
      
        if (quality.videoQuality != selectedQuality.videoQuality) {
            if (selectedQuality.privateId == null) {
                onPremium();

            }
            else {
                setQuality(selectedQuality);
                const src = await ContentService.fetchVideo(selectedQuality);
                localStorage.setItem("savedPrefQuality", selectedQuality.videoQuality);
            
                onSrcChanged(src);
            }

        }

        setState('def');

    };

    const getDisplayQualityName = (qualityCode) => {
        switch (qualityCode) {
            case 'FULL_HD':
                return '1080p';
            case 'QHD':
                return 'QHD (2K)';
            case 'HD':
                return '720p';
            case 'UHD':
                return 'UHD (4K)';
            default:
                return qualityCode; // Fallback to the original code if no match is found
        }
    };

    const renderContent = () => {
        switch (state) {
            case 'def':
                return (<>
                    <div className="settings-option" onClick={() => setState('quality')}>
                        <span>Качество</span>
                        <span className={`option-value ${['UHD', 'QHD'].includes(quality.videoQuality) ? 'high' : ''}`}> {getDisplayQualityName(quality.videoQuality)}</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('speed')}>
                        <span>Скорость</span>
                        <span className="option-value">{speed}x</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('sound')}>
                        <span>Озвучка</span>
                        <span className="option-value">{dubbing}</span>
                    </div>
                    {localStorage.getItem("editor") === "true" || localStorage.getItem("mod") === "true" ? (
                        <div>
                            <div className="settings-option debug" onClick={() => setState('marks')}>
                                <span>Редактирование меток</span>
                            </div>
                        </div>
                    ) : ""}
                    <div className="settings-option debug" onClick={() => setState('debug')}>
                        <span>Для разработчиков</span>
                    </div>
                </>);
            case 'speed':
                return (
                    <>
                        <div className="settings-option" onClick={() => handleSpeedChange(1)}>1x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.25)}>1.25x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.5)}>1.5x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.75)}>1.75x</div>
                        <div className="settings-option">
                            <p className="settings-text">Чтобы серия была засчитана, смотрите большую часть на 1x</p>
                        </div>
                    </>
                );
            case 'quality':
                return (
                    <>
                        {qualities.map((qualityOption) => (
                            <div className="settings-option" key={qualityOption.privateId} onClick={() => handleQualityChange(qualityOption)}>
                                {getDisplayQualityName(qualityOption.videoQuality)}
                            </div>
                        ))}
                    </>
                );
            case 'marks':
                return (
                    <>
                        <div className="settings-option" onClick={() => setState('def')}>Закрыть</div>
                        <SkipMarkForm videoRef={videoRef} series={series} />
                    </>
                );
            case 'sound':
                return (
                    <>
                        <div className="settings-option" onClick={() => setState('def')}>Стандарт</div>
                    </>
                );
            case 'debug':
                return (
                    <div className="settings-option" onClick={() => setState('def')}>
                        <p className="debug-text">{JSON.stringify(debugInfo)}</p>
                    </div>
                );
        }
    }

    return (
        <div className={`settings-menu ${!visible && state !== 'debug' ? 'hidden' : ''}`}>
            {renderContent()}

        </div>
    );
};

export default SettingsMenu;
