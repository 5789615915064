import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Footer.css';
import telegram from "../assets/telegramIcon.svg";
import server from "../assets/serverIcon.svg";
import support from "../assets/supportIcon.svg";

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container">
        <div className="row flex-grow">
          <div className="col-md-6 col-sm-12 text-center">
            <p>animix 2024. Применяются рекомендательные технологии</p>
            <a className='footerButton' href='https://t.me/animixlib'> 
              <img src={telegram} alt="Telegram"/> Мы в Telegram
            </a>
            <a className='footerButton' href='https://t.me/animixlib_bot'>
              <img src={support} alt="Support"/> Поддержка
            </a>
            <a className='footerButton' href='https://status.animix.lol'>
              <img src={server} alt="Server Status"/> Статус серверов
            </a>
          </div>
          <ul className="footer-links list-unstyled">
            <li><Link to="/about">О нас</Link></li>
            <li><Link to="/community">Правила сообщества</Link></li>
            <li><Link to="/legal">Правообладателям</Link></li>
            <li><Link to="/privacy">Политика конфиденциальности</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
