import React, { useState, useEffect } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling
import './LeaderboardPage.css'; // Assuming you have a CSS file for styling

import Header from './../../components/Header';
import Footer from '../../components/Footer';
import ApiService from '../../services/ApiService';

import { useParams, useNavigate } from 'react-router-dom';
import ProfilePic from '../../components/profilepic/ProfilePic';
import placeholder from '../../assets/placeholder.png';
import YandexAdComponent from '../../components/YandexAdComponent';

const LeaderboardPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const [loading, setLoading] = useState(true);
    const [leaderboard, setLeaderboard] = useState([]);
    const [selectedTab, setTab] = useState("all");

    const navigate = useNavigate();

    const { id } = useParams();

    var fetched = false;
    useEffect(() => {


        const handleScroll = () => {
            const position = window.innerHeight + window.scrollY;
            const bottom = document.documentElement.offsetHeight;

            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        fetchData("all"); // Initial data fetch

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Trigger fetchData when page changes



    const fetchData = async (tab) => {
        if (!fetched) {
            fetched = true;
            let data;
            if (tab === "all") {
                data = await ApiService.getLeaderboard();

            }
            else {
                data = await ApiService.getLeaderboardWeek();
            }


            if (data.type === "success") {
                setLeaderboard(data.data);
                setLoading(false);
            }

        }

    };

    const handleTabChange = (tabName) => {
        setTab(tabName);
        setLeaderboard(null)
        setLoading(true)
        fetched = false;
        fetchData(tabName);
    };
    return (

        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">

                <h1 className='tasty-title'>Лидерборд</h1>
                <h3 >Топ по онлайну</h3>
                <div className='leader-tabs' style={{ marginBottom: 20 }}>
                    <button
                        style={{ margin: '4px' }}
                        onClick={() => handleTabChange("all")}
                        className={selectedTab === "all" ? 'tab-button selected' : 'tab-button'}    >
                        За всё время
                    </button>
                    <button
                        style={{ margin: '4px' }}
                        onClick={() => handleTabChange("week")}
                        className={selectedTab === "week" ? 'tab-button selected' : 'tab-button'}    >
                        За неделю
                    </button>
                </div>

                <div className='tasty-underline'></div>

                {(loading) ? <p>Загружаем больше...</p> : <div className="container-wrapper">



                    <div className="leaders-container">
                        {leaderboard.map((user) => (
                            <a onClick={() => {navigate(`/profile/${user.nickname}`)}} style={{cursor: "pointer"}}>
                                <div className={`user-leaderboard-con ${leaderboard.indexOf(user) == 0 ? "top" : ""} ${user.subscribed ? "sub" : ""}`}
                                >
                                    {user.subscribed ? <img className="background-image"  loading="lazy"
                                     onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = placeholder;
                                    }} src={`https://api.animix.lol/account/getProfileData/${user.id}_profile_background.png`}></img> : ""}

                                    <div style={{ zIndex: 100 }}>
                                        <h1>{leaderboard.indexOf(user) + 1}</h1>
                                        <ProfilePic isBig={false} account={user} />
                                        <h4 style={{ zIndex: 900000 }} >{user.nickname.substring(0, Math.min(user.nickname.length, 15))}
                                        </h4>

                                        {selectedTab === "all" ? <h4>{Math.round(user.watchCount * 20 / 60 * 10) / 10} часов просмотра</h4> :
                                          <h4>{Math.round(user.watchCountLastWeek * 20 / 60 * 10) / 10} часов просмотра</h4>}</div>
                                </div>

                            </a>


                        ))}

                    </div></div>}

                <p>Чтобы попасть в топ, просто побейте нижний порог</p>
                <YandexAdComponent blockId="R-A-8131234-1" />

            </div>
            <Footer />
        </div>
    );
};

export default LeaderboardPage;
