import axios from 'axios';

const API_URL = 'https://api.animix.lol';
const CDN_URL = 'https://cdn.animix.lol';


const ApiService = {
    registerUser: async (nickname, password, birthDate, token) => {
        try {
            const response = await axios.post(`${API_URL}/auth/create`, {
                nickname,
                password,
                birthDate,
                token
            });
            return response.data;
        } catch (error) {
            throw new Error('Error registering user');
        }
    },

    login: async (nickname, password) => {
        try {
            const response = await axios.post(`${API_URL}/auth/login`, {
                nickname,
                password
            });
            return response.data;
        } catch (error) {
            throw new Error('Error registering user');
        }
    },

    setSeries: async (seriesId, season, series) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/admin/setAnimeSeries?seriesId=${seriesId}&season=${season}&series=${series}`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getFrames: async () => {
        try {


            const response = await axios.get(`${API_URL}/frames.json`);

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    search: async (query) => {
        try {


            const response = await axios.get(`${API_URL}/search/search?query=` + query);

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    changeEmail: async (email) => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

          


            const response = await axios.post(`${API_URL}/account/changeEmail`, email,  {
                headers: {
                    Authorization: `Bearer ${sessionKey}`,
                    'Content-Type': 'text/plain'
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },


    changePassword: async (newPassword) => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/account/changePassword`, { password: newPassword }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },

    editSelf: async (account) => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/account/update`, account, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },


    getNotifications: async (page) => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.get(`${API_URL}/notifications/get?page=` + page, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
           // throw new Error('Error editing account');
        }
    },

    getVast: async () => {
        try {


        


            const response = await axios.get(`${API_URL}/ads/getVast`);

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },

    getRecs: async () => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

          


            const response = await axios.get(`${API_URL}/recs/get`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },

    
    getEmail: async () => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.get(`${API_URL}/account/getEmail`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },


    getCheckout: async (month, email) => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.get(`${API_URL}/subscription/getCheckout?month=` + month + "&email=" + email, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },

    getCheckoutGift: async (month, nickname, email) => {
        try {





            const response = await axios.get(`${API_URL}/subscription/getGiftCheckout?month=` + month + "&nickname=" + nickname + "&email=" + email, {

            });


            return response.data;
        } catch (error) {
            throw new Error('Error editing account');
        }
    },

    getSelf: async () => {
        try {


            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/account/getSelf`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });
            if (response.data.type === "success") {
                localStorage.setItem("selectedPicFrame", response.data.data.selectedPicFrame);
                localStorage.setItem("profileId", response.data.data.id);
                localStorage.setItem("nickname", response.data.data.nickname);
                localStorage.setItem("subscribed", response.data.data.subscribed);
                localStorage.setItem("mod", response.data.data.mod);
                localStorage.setItem("editor", response.data.data.editor);
            }
            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getCollections: async (id) => {
        try {


            const response = await axios.get(`${API_URL}/collections/getCollections?userId=` + id);

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getCollection: async (id) => {
        try {


            const response = await axios.get(`${API_URL}/collections/getCollection?collectionId=` + id);

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },


    getPublicCategories: async () => {
        try {


            const response = await axios.get(`${API_URL}/anime/getCategories`);

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getCategories: async () => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/admin/getCategories`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    setCategory: async (category) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.post(`${API_URL}/admin/setCategory`, category, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getCollections: async (userId) => {
        try {

            const response = await axios.get(`${API_URL}/collections/getCollections?userId=` + userId);


            return response.data;
        } catch (error) {
            throw new Error('Error getting collections');
        }
    },


    setSubscribed: async (subscribe, animeId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/anime/subscribeAnime?animeId=` + animeId + "&subscribe=" + subscribe, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error getting collections');
        }
    },


    setAutopay: async (autopay) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/subscription/setAutopay?autopay=` + autopay, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error getting collections');
        }
    },



    addCollection: async (collectionId, animeId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.post(`${API_URL}/collections/addToCollection`, {
                collectionId: collectionId,
                animeId: animeId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error getting collections');
        }
    },


    getWatchHistoryProfile: async (userId, page) => {
        try {


            const response = await axios.get(`${API_URL}/anime/getUserWatchHsitory?userId=` + userId + "&page=" + page);


            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getWatchHistory: async (seriesId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/anime/getWatchHsitory?seriesId=` + seriesId, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },

    getLastWatch: async () => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            const response = await axios.get(`${API_URL}/anime/getLastWatch`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing series');
        }
    },


    startWatch: async (seriesId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.get(`${API_URL}/anime/startWatch?seriesId=${seriesId}`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },

    endWatch: async (seriesId, timeSec, sessionKey) => {
        try {


            const response = await axios.get(`${API_URL}/anime/endWatch?seriesId=${seriesId}&timeSec=${timeSec}`, {
                headers: {
                    Authorization: sessionKey.startsWith("unknown") ? sessionKey : `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error end watch', error);
        }
    },


    addFriend: async (userId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/account/addFriend`, { userId: userId }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },


    removeFriend: async (userId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/account/removeFriend`, { userId: userId }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },

    getComments: async (topic, page) => {
        try {

            const response = await axios.get(`${API_URL}/comments/getComments?topic=` + topic + "&page=" + page);

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },


    deleteComment: async (commentId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }




            const response = await axios.post(`${API_URL}/comments/deleteComment`, commentId, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },


    leaveComment: async (message, topic) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/comments/leaveComment`, {
                topic: topic,
                message: message
            }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },


    leaveReview: async (message, starsCount, animeId) => {
        try {
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }



            const response = await axios.post(`${API_URL}/reviews/leaveReview`, {
                message: message,
                stars: starsCount,
                animeId: animeId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error start watch');
        }
    },

    banUser: async (userId, ban) => {
        try {

            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }


            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/admin/banUser?userId=` + userId + "&ban=" + ban, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });


            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    getAdminReviews: async (page) => {
        try {

            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }


            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/admin/getReviews?page=` + page, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });


            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    getUsers: async (page) => {
        try {

            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }


            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/admin/getUsers?page=` + page, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });


            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },


    getLeaderboard: async () => {
        try {

            const response = await axios.get(`${API_URL}/account/getLeaderboard`);

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },


    
    getLeaderboardWeek: async () => {
        try {

            const response = await axios.get(`${API_URL}/account/getLeaderboardWeekly`);

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },



    getReviews: async (animeId, page) => {
        try {

            const response = await axios.get(`${API_URL}/reviews/getReviews?animeId=` + animeId + "&page=" + page);

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    getSeries: async (animeId) => {
        try {

            const response = await axios.get(`${API_URL}/anime/getSeries?animeId=` + animeId);

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    isBlacklisted: async (animeId) => {
        try {


            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/anime/isBlacklisted?animeId=` + animeId);

            return response.data;
        } catch (error) {
            throw error;
        }
    },


    getAnimeByIds: async (idsList) => {
        try {


            // Include the sessionKey in the request headers
            const response = await axios.post(`${API_URL}/anime/getEntities`, idsList);

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    get: async (nickname) => {
        try {

            var sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                sessionKey = "unknown";
            }

            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/account/get?username=` + nickname, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getAnime: async (id) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            let sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                sessionKey = "unknown"
            }
            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/anime/get?id=` + id, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    getQualities: async (animeId, seriesNum, seasonNum) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            let sessionKey = localStorage.getItem('sessionKey');
    
            if (!sessionKey) {
                sessionKey = "unknown";
            }
    
            // Include the sessionKey in the request headers and pass the required parameters in the request
            const response = await axios.get(`${API_URL}/anime/getSeriesQualities?animeId=` + animeId + "&seriesNum=" + seriesNum + "&seasonNum=" + seasonNum, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                },
    
            });
    
            return response.data;
        } catch (error) {
            throw new Error('Error getting series qualities');
        }
    },

    getAnimeSeriesList: async (animeId) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }


            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/admin/getAnimeSeries?animeId=` + animeId, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });


            return response.data;
        } catch (error) {
            throw new Error('Error getting series: ' + error);
        }
    },

    getPublicAnimeList: async (page) => {
        try {

            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/anime/getAll?page=` + page);


            return response.data;
        } catch (error) {
            throw new Error('Error getting user: ');
        }
    },


    getAnimeList: async () => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Include the sessionKey in the request headers
            const response = await axios.get(`${API_URL}/admin/getAnimeList`, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });


            return response.data;
        } catch (error) {
            throw new Error('Error getting user');
        }
    },

    deleteSeries: async (id) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Include the sessionKey in the request headers


            const response = await axios.delete(`${API_URL}/admin/deleteAnimeSeries?id=` + id, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing anime: ' + error.message);
        }
    },

    editAnime: async (anime) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Include the sessionKey in the request headers


            const response = await axios.post(`${API_URL}/admin/edit`, anime, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing anime: ' + error.message);
        }
    },

    updateSkipMark: async (skipMark) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Include the sessionKey in the request headers


            const response = await axios.post(`${API_URL}/skipMarks/updateSkipMark`, skipMark, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`
                }
            });

            return response.data;
        } catch (error) {
            throw new Error('Error editing anime: ' + error.message);
        }
    },


    getSkipMarks: async (animeId, seriesNum, seasonNum) => {
        try {

            const response = await axios.get(`${API_URL}/skipMarks/get?animeId=`+ animeId + "&seriesNum=" + seriesNum + "&seasonNum=" + seasonNum);

            return response.data;
        } catch (error) {
            throw new Error('Error editing anime: ' + error.message);
        }
    },

    getWatchSession: async (privateId, server) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage


            // Include the sessionKey in the request headers
            const response = await axios.get(server + `content/getWatchSession?privateId=` + privateId
            );

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },


    getOptimalServer: async (privateId) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage


            // Include the sessionKey in the request headers
            const response = await axios.get(CDN_URL + `/balancer/getOptimalServer?privateId=` + privateId
            );

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },



    uploadProfilePic: async (file) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Create a FormData object to send the file and other parameters
            const formData = new FormData();
            formData.append('file', file);




            // Include the sessionKey in the request headers
            const response = await axios.post(`${API_URL}/account/uploadProfilePicture`, formData, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`,
                    'Content-Type': 'multipart/form-data', // Set the content type for FormData
                },
            });

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },

    uploadProfileBack: async (file) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Create a FormData object to send the file and other parameters
            const formData = new FormData();
            formData.append('file', file);





            // Include the sessionKey in the request headers
            const response = await axios.post(`${API_URL}/account/uploadProfileBackground`, formData, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`,
                    'Content-Type': 'multipart/form-data', // Set the content type for FormData
                },
            });

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },

    uploadThumb: async (file, animeId) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage
            const sessionKey = localStorage.getItem('sessionKey');

            if (!sessionKey) {
                throw new Error('Session key not found');
            }

            // Create a FormData object to send the file and other parameters
            const formData = new FormData();
            formData.append('file', file);
            formData.append('animeId', Number(animeId));



            // Include the sessionKey in the request headers
            const response = await axios.post(`${API_URL}/admin/uploadThumb`, formData, {
                headers: {
                    Authorization: `Bearer ${sessionKey}`,
                    'Content-Type': 'multipart/form-data', // Set the content type for FormData
                },
            });

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },

    uploadAnime: async (file, animeId, seriesNum, seasonNum, url, key, priority, notify, quality) => {
        try {
            // Retrieve the sessionKey (authorization token) from localStorage


            // Create a FormData object to send the file and other parameters
            const formData = new FormData();
            formData.append('file', file);
            formData.append('animeId', Number(animeId));
            formData.append('seriesNum', parseInt(seriesNum));
            formData.append('seasonNum', parseInt(seasonNum));
            formData.append('priority', parseInt(priority));
            formData.append('notify', notify);
            formData.append('quality', quality);



            // Include the sessionKey in the request headers
            const response = await axios.post(`${url}/admin/uploadAnime`, formData, {
                headers: {
                    Authorization: `Bearer ${key}`,
                    'Content-Type': 'multipart/form-data', // Set the content type for FormData
                },
            });

            return response.data;
        } catch (error) {
            throw new Error('Error uploading anime');
        }
    },
};




export default ApiService;
