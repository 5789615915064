import React, { useState, useEffect } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import logo from './../assets/logo_150.svg'; // Import logo.svg from the assets folder
import Header from './../components/Header';
import Footer from '../components/Footer';

const PrivacyPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };
        document.title = 'Политика конфиденциальности';
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article">
                <div className="">



                    <h1>Политика конфиденциальности</h1>
                    <p>
                        Дата вступления в силу: 26/04/2024<br /><br />
                        animix.lol (далее "Мы", "Наш", "Сайт" или "Сервис") ценит вашу конфиденциальность и стремится обеспечить защиту ваших личных данных. Настоящая Политика конфиденциальности объясняет, как мы собираем, используем и защищаем информацию, которую вы предоставляете при использовании нашего сайта и мобильного приложения.

                        <h4>Сбор информации</h4>
                        Мы можем собирать различные типы информации о вас при использовании нашего сайта и мобильного приложения, включая, но не ограничиваясь:

                        - Личная информация, которую вы предоставляете добровольно при регистрации на сайте или при заполнении форм обратной связи.
                        - Информация о вашем устройстве и интернет-соединении, включая IP-адрес, тип браузера, данные о вашем местоположении и т. д., которая автоматически собирается при посещении сайта или использовании мобильного приложения.
                        - Cookies и аналогичные технологии, включая Yandex.Metrica и AppMetrica, которые позволяют нам собирать информацию о вашем взаимодействии с сайтом и приложением для улучшения их работы.
                        - Информация о поведении пользователей в мобильном приложении для анализа и улучшения качества предоставляемых услуг.

                        Пользователь понимает, что в случае использовния им блокировщиков рекламы, сервис может в любой момент принять решении об ограничении доступа к платформе.

                        <h4>Использование информации</h4>
                        Мы используем собранную информацию для следующих целей:<br /><br />

                        - Предоставление вам доступа к нашему контенту и услугам.
                        - Повышение качества предоставляемых услуг и адаптация сайта и приложения к вашим потребностям.
                        - Отправка вам информации о новостях, акциях и других событиях, которые могут вас заинтересовать (если вы согласились получать такие уведомления).
                        - Обеспечение безопасности сайта и мобильного приложения, предотвращение мошенничества.
                        - Периодический контакт с вами для предоставления важной информации, уведомлений и маркетинговых акций.

                        <h4>Раскрытие информации</h4>
                        Мы не продаем, не обмениваем и не передаем вашу личную информацию третьим лицам без вашего согласия, за исключением случаев, предусмотренных законом, или если это необходимо для обеспечения вашей безопасности или безопасности других пользователей. Однако мы можем делиться анонимной или обобщённой информацией с партнёрами, такими как аналитические сервисы и рекламодатели, для улучшения наших услуг.

                        Примечание: Приложение использует сторонние службы, которые имеют свои собственные политики конфиденциальности в отношении обработки данных. Ниже приведены ссылки на Политику конфиденциальности сторонних поставщиков услуг, используемых Приложением:

                        - [Google Play Services](https://policies.google.com/privacy)
                        - [Yandex AppMetrica](https://yandex.com/legal/appmetrica_privacy_policy/)

                        Поставщик услуг может раскрывать информацию, предоставленную пользователем и автоматически собранную:

                        - по требованию закона, например, для соблюдения повестки или аналогичного юридического процесса;
                        - когда они добросовестно считают, что раскрытие необходимо для защиты их прав, обеспечения вашей безопасности или безопасности других лиц, расследования мошенничества или ответа на запрос правительства;
                        - своим доверенным поставщикам услуг, которые работают от их имени, не имеют независимого использования информации, которую мы раскрываем им, и согласились соблюдать правила, изложенные в данной политике конфиденциальности.

                        <h4>Права на отказ</h4>
                        Вы можете легко прекратить сбор информации Приложением, удалив его. Вы можете воспользоваться стандартными процессами удаления, доступными на вашем мобильном устройстве или через рынок мобильных приложений.

                        <h4>Политика хранения данных</h4>
                        Поставщик услуг будет хранить предоставленные пользователем данные до тех пор, пока вы используете Приложение и в течение разумного времени после этого. Если вы хотите, чтобы мы удалили предоставленные вами данные, пожалуйста, свяжитесь с нами по адресу obdristaev@gmail.com, и они ответят в разумные сроки.

                        <h4>Дети</h4>
                        Поставщик услуг не использует Приложение для сознательного сбора данных от или маркетинга для детей младше 13 лет. Приложение не предназначено для лиц младше 13 лет. Поставщик услуг не собирает сознательно личную информацию от детей младше 13 лет. В случае обнаружения, что ребенок младше 13 лет предоставил личную информацию, Поставщик услуг немедленно удалит эту информацию с серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам личную информацию, пожалуйста, свяжитесь с Поставщиком услуг (obdristaev@gmail.com), чтобы они могли принять необходимые меры.

                        <h4>Защита данных</h4>
                        Мы принимаем разумные меры для защиты вашей личной информации от несанкционированного доступа, использования или раскрытия. Однако нельзя гарантировать абсолютную безопасность передачи данных в интернете.

                        <h4>Изменения в политике конфиденциальности</h4>
                        Мы оставляем за собой право периодически обновлять или изменять эту Политику конфиденциальности. Мы рекомендуем вам периодически проверять эту страницу на обновления. Поставщик услуг уведомит вас о любых изменениях, обновив эту страницу новой Политикой конфиденциальности. Продолжение использования Приложения считается одобрением всех изменений.

                        <h4>Ваше согласие</h4>
                        Используя наш сайт и мобильное приложение, вы соглашаетесь с условиями нашей Политики конфиденциальности и её возможными изменениями.<br /><br /><br />

                        Если у вас возникли вопросы или предложения относительно нашей Политики конфиденциальности, пожалуйста, свяжитесь с нами по адресу obdristaev@gmail.com.<br />
                        Дата последнего обновления: 26/04/2024
                    </p>


                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPage;
